//  Region Import External Lib (e.g React, etc)
import CircularProgress from "@mui/material/CircularProgress";

import { CustomSpinnerProps } from "./CustomSpinner.types";
import colors from "theme/muiColors";

export default function CustomSpinner(props: CustomSpinnerProps) {
  return (
    <CircularProgress
      size={45}
      style={{ color: colors.primary }}
      thickness={4}
      {...props}
    />
  );
}
