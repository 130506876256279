import { Link } from "react-router-dom";

import {
  BrandTitle,
  CustomPaper,
  ImageBrand,
} from "./CustomCardBrandProduct.styles";
import { CustomCardBrandProductProps } from "./CustomCardBrandProduct.types";
import { menuUrl } from "constants/";
import { transformUnderscoreToUrl } from "utils";

function CustomCardBrandProduct({
  image,
  title,
  brandId,
}: CustomCardBrandProductProps) {
  return (
    <Link
      to={menuUrl.detailBrandUrl(transformUnderscoreToUrl(brandId))}
      style={{ textDecoration: "none" }}
    >
      <CustomPaper>
        <ImageBrand src={image} alt={image} />
        <BrandTitle>{title}</BrandTitle>
      </CustomPaper>
    </Link>
  );
}

export default CustomCardBrandProduct;
