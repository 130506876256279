import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { rem, vwMobile } from "theme/muiSizes";

export const CustomPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  borderRadius: "10px",
  width: "100%",
  height: "240px",
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  transition: "all 0.3s",
  cursor: "pointer",
  boxShadow: "none",
  "&:hover": {
    border: `1px solid ${theme.palette.primary.main}`,
    transform: "scale(1.1) translateY(-2px)",
  },
  [theme.breakpoints.down(600)]: {
    height: vwMobile(180),
  },
  [theme.breakpoints.down(440)]: {
    borderRadius: vwMobile(10),
    "&:hover": {
      borderWidth: vwMobile(1),
      transform: `scale(1.1) translateY(${vwMobile(-2)})`,
    },
  },
}));

export const ImageBrand = styled("img")(({ theme }) => ({
  height: "180px",
  width: "100%",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  [theme.breakpoints.down(600)]: {
    height: vwMobile(120),
  },
  [theme.breakpoints.down(440)]: {
    borderTopLeftRadius: vwMobile(10),
    borderTopRightRadius: vwMobile(10),
  },
}));

export const BrandTitle = styled(Typography)<TypographyProps>(() => ({
  fontSize: rem(15),
  fontFamily: "Barlow-Medium",
  flexGrow: 1,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  lineHeight: 1.2,
}));
