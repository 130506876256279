import {
  BodyParagraph,
  CopyrightContainer,
  CustomButtonFooter,
  FooterContainer,
  ImageBantuan,
  ImageCustom,
  ImagePembayaran,
  PaperBank,
  TitleParagraph,
} from "./Footer.styles";
import ContentPasteSearch from "@mui/icons-material/ContentPasteSearch";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { LeftBox } from "styles";
import { Link } from "react-router-dom";
import { menuUrl } from "constants/";
import { useMediaQuery } from "react-responsive";

function Footer() {
  const isMobile = useMediaQuery({ maxWidth: 440 });

  const handleClickYoutube = () => {
    window.open("https://www.youtube.com/@DrazenKanzen", "_blank");
  };

  const handleClickInstagram = () => {
    window.open("https://www.instagram.com/drazen.kanzen/", "_blank");
  };

  const handleClickTiktok = () => {
    window.open("https://www.tiktok.com/@drazenkanzen", "_blank");
  };

  const handleClickWhatsapp = () => {
    window.open("https://wa.me/+62881081917409", "_blank");
  };

  const handleClickTelegram = () => {
    window.open("https://t.me/digipopo", "_blank");
  };

  return (
    <>
      <FooterContainer>
        <Grid container columnSpacing={6} rowSpacing={4}>
          <Grid item xs={12} sm={5} md={6} lg={4}>
            <LeftBox sx={{ zIndex: 2 }}>
              <ImageCustom
                src={
                  process.env.PUBLIC_URL +
                  "/assets/icons/logo-drazen-kanzen-white-primary.png"
                }
                alt="drazen-kanzen"
              />
            </LeftBox>
            <Box sx={{ marginTop: "0.7rem", zIndex: 2 }}>
              Website top-up tercepat, termurah, dan terpercaya di Indonesia.
              Hanya dalam waktu 1 detik kredit permainan akan ditambahkan ke
              dalam akunmu secara instan. Top up Mobile Legends, Free Fire,
              Ragnarok M, dan berbagai game lainnya segera!
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={6} lg={8}>
            <Grid container columnSpacing={isMobile ? 3 : 6} rowSpacing={4}>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Menu kami</TitleParagraph>
                <BodyParagraph>
                  <Link to={menuUrl.base} style={{ textDecoration: "none" }}>
                    <CustomButtonFooter>
                      <HomeIcon
                        sx={{ marginRight: "0.3rem", fontSize: "1.3rem" }}
                      />
                      Home
                    </CustomButtonFooter>
                  </Link>
                  <Link
                    to={menuUrl.riwayatPesanan}
                    style={{ textDecoration: "none" }}
                  >
                    <CustomButtonFooter>
                      <ReceiptLongIcon
                        sx={{ marginRight: "0.3rem", fontSize: "1.3rem" }}
                      />
                      Riwayat Transaksi
                    </CustomButtonFooter>
                  </Link>
                  <Link
                    to={menuUrl.cekPesanan}
                    style={{ textDecoration: "none" }}
                  >
                    <CustomButtonFooter>
                      <ContentPasteSearch
                        sx={{ marginRight: "0.3rem", fontSize: "1.3rem" }}
                      />
                      Cek Pesanan
                    </CustomButtonFooter>
                  </Link>
                </BodyParagraph>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Ikuti akun kami</TitleParagraph>
                <BodyParagraph>
                  <CustomButtonFooter onClick={handleClickYoutube}>
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/logo-youtube.png"
                      }
                    />
                    YouTube
                  </CustomButtonFooter>
                  <CustomButtonFooter onClick={handleClickInstagram}>
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/logo-instagram.png"
                      }
                    />
                    Instagram
                  </CustomButtonFooter>
                  <CustomButtonFooter onClick={handleClickTiktok}>
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL + "/assets/icons/logo-tiktok.png"
                      }
                      sx={{
                        backgroundColor: "white",
                        padding: "0.09rem",
                        borderRadius: "0.25rem",
                      }}
                    />
                    TikTok
                  </CustomButtonFooter>
                </BodyParagraph>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Saluran pembayaran</TitleParagraph>
                <Grid container columnSpacing={1.5} rowSpacing={2}>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bca.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bni.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bri.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL + "/assets/images/mandiri.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bsi.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/permata-bank.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/qris.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/ovo.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/dana.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/gopay.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/linkaja.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/shopeepay.png"}
                      />
                    </PaperBank>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Butuh bantuan?</TitleParagraph>
                <BodyParagraph>
                  <CustomButtonFooter
                    onClick={handleClickWhatsapp}
                    variant="outlined"
                    sx={{
                      width: "100%",
                      marginBottom: "1rem",
                      fontFamily: "Barlow-Medium",
                    }}
                  >
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/logo-whatsapp.png"
                      }
                    />
                    WhatsApp
                  </CustomButtonFooter>
                  <CustomButtonFooter
                    onClick={handleClickTelegram}
                    variant="outlined"
                    sx={{
                      width: "100%",
                      marginBottom: "1rem",
                      fontFamily: "Barlow-Medium",
                    }}
                  >
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/logo-telegram.png"
                      }
                    />
                    Telegram
                  </CustomButtonFooter>
                </BodyParagraph>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FooterContainer>
      <CopyrightContainer>
        2023 Drazen Kanzen &copy; All Rights Reserved.
      </CopyrightContainer>
    </>
  );
}

export default Footer;
