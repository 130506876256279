import { handleError } from "utils/handleToast";

export const responseErrorHandler = (error: any) => {
  if (!error?.message) return;

  const errorObject = JSON.parse(error.message);

  if (errorObject?.message?.length > 0) {
    handleError([errorObject.message]);
  } else if (errorObject.data) {
    handleError(errorObject?.data?.errors?.messages ?? ["Error"]);
  }
};
