import TextField from "@mui/material/TextField";
import { alpha, styled } from "@mui/material/styles";

import { defaultBorderRadius, defaultFontSize } from "constants/";
import { rem } from "theme/muiSizes";
import { CustomTextFieldProps } from "types";

export const CustomTextField = styled(TextField, {
  shouldForwardProp: (props) =>
    props !== "isError" &&
    props !== "readView" &&
    props !== "isMultiAutocomplete",
})<CustomTextFieldProps>(
  ({ isError, readView, multiline, isMultiAutocomplete, theme }) => ({
    ".MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
      WebkitTextFillColor: theme.palette.text.disabled,
    },
    "&.MuiTextField-root": {
      width: "100%",
      borderRadius: defaultBorderRadius,
    },
    ".MuiInputLabel-root": {
      fontSize: defaultFontSize,
      color: theme.palette.text.primary,
      top: "-0.25rem",
      "&.MuiInputLabel-shrink": {
        top: "0.05rem",
      },
      "&.Mui-disabled": {
        color: theme.palette.text.disabled,
      },
      "&.Mui-focused": {
        color: theme.palette.text.primary,
      },
    },
    ".MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
      {
        border: "none",
      },
    ".MuiOutlinedInput-root": {
      minHeight: rem(50),
      ...(multiline && {
        height: "auto",
        overflow: "auto",
      }),
      ...(isMultiAutocomplete && {
        height: "auto",
        overflow: "auto",
      }),
      padding: "0.875rem",
      borderRadius: defaultBorderRadius,
      ...(readView && {
        padding: 0,
        minHeight: 0,
        border: "none",
      }),
      " input[type=number]": {
        MozAppearance: "textfield",
      },
      " input": {
        "::-webkit-outer-spin-button": {
          display: "none",
          WebkitAppearance: "none",
          margin: 0,
        },
        "::-webkit-inner-spin-button": {
          display: "none",
          WebkitAppearance: "none",
          margin: 0,
        },
        ":-webkit-autofill": {
          transition:
            "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s",
          transitionDelay: "background-color 5000s, color 5000s",
          "&:hover": {
            transition:
              "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s",
            transitionDelay: "background-color 5000s, color 5000s",
          },
          "&:focus": {
            transition:
              "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s",
            transitionDelay: "background-color 5000s, color 5000s",
          },
          "&:active ": {
            transition:
              "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s",
            transitionDelay: "background-color 5000s, color 5000s",
          },
        },
      },
      backgroundColor: "transparent",
      "&.Mui-focused": {
        minHeight: rem(50),
        border: "none",
        borderRadius: defaultBorderRadius,
        cursor: "text",
        ".MuiOutlinedInput-notchedOutline": {
          border: `0.063rem solid ${
            isError ? theme.palette.error.main : theme.palette.primary.main
          }`,
        },
      },
      ".MuiOutlinedInput-notchedOutline": {
        border: `0.063rem solid ${
          isError ? theme.palette.error.main : theme.palette.primary.main
        }`,
        fontSize: defaultFontSize,
        "&.Mui-disabled": {
          border: "none",
        },
      },
      ".MuiOutlinedInput-input": {
        padding: 0,
        color: theme.palette.text.primary,
        fontSize: defaultFontSize,
        fontWeight: 400,
        fontFamily: "Barlow-Regular",
        ...(isMultiAutocomplete && {
          height: "auto",
          overflow: "auto",
        }),
        "&::placeholder": {
          color: alpha(theme.palette.text.primary, 0.5),
          fontSize: defaultFontSize,
        },
        "&.Mui-disabled": {
          cursor: "not-allowed",
          ...(readView && {
            paddingLeft: 0,
            paddingRight: 0,
            border: "none",
          }),
        },
        ...(readView && {
          ":hover": {
            cursor: "pointer",
          },
        }),
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.text.primary,
        borderRadius: defaultBorderRadius,
        cursor: "not-allowed",
        ...(readView && {
          backgroundColor: "#ffffff",
          border: "none",
        }),
      },
      ".MuiAutocomplete-popupIndicator": {
        color: theme.palette.text.primary,
        "&.Mui-disabled": {
          color: theme.palette.text.disabled,
        },
      },
      ".MuiAutocomplete-endAdornment": {
        top: "initial",
      },
    },
  })
);
