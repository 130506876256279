import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface AppSlice {
  isLoading: boolean;
}

// Define the initial state using that type
const initialState: AppSlice = {
  isLoading: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsLoadingPage: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

// actions
export const { setIsLoadingPage } = appSlice.actions;

// reducer
export default appSlice.reducer;
