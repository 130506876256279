import { createTheme } from "@mui/material";
import "./fonts.scss";

const themeSettings = () => {
  return {
    palette: {
      primary: {
        main: "#00BDFF",
        light: "#00BDFF20",
      },
      secondary: {
        main: "#282828",
        light: "#28282820",
      },
      text: {
        primary: "#ffffff",
        secondary: "#282828",
        disabled: "#d3d3d3",
      },
      background: {
        default: "#282828",
        paper: "#1f1f1f",
      },
      info: {
        main: "#E2E5E8",
      },
      warning: {
        main: "#FFD027",
        chip: "#FFD027CC",
        chipLight: "#FFD02780",
      },
      success: {
        main: "#23C35A",
        chip: "#23C35ACC",
        chipLight: "#23C35A80",
      },
      error: {
        main: "#C03221",
        chip: "#C03221CC",
        chipLight: "#C0322180",
      },
      disabled: {
        main: "#7B91B0",
        chip: "#E2E5E8",
        chipLight: "#CFD3D880",
      },
    },
    typography: {
      fontFamily: ["Barlow-Regular"].join(","),
      h1: {
        fontFamily: ["Barlow-SemiBold"].join(","),
        fontSize: "2rem",
      },
      h2: {
        fontFamily: ["Barlow-SemiBold"].join(","),
        fontSize: "1.5rem",
      },
      h3: {
        fontFamily: ["Barlow-Medium"].join(","),
        fontSize: "1.25rem",
      },
      h4: {
        fontFamily: ["Barlow-Medium"].join(","),
        fontSize: "1rem",
      },
      h5: {
        fontFamily: ["Barlow-Regular"].join(","),
        fontSize: "0.875rem",
      },
      h6: {
        fontFamily: ["Barlow-Regular"].join(","),
        fontSize: "0.6875rem",
      },
    },
  };
};

const theme = createTheme(themeSettings());

export default theme;
